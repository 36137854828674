<template>
	<div id="cpm_liveType">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input-search
                    v-model:value="lcState.key"
                    placeholder="分类名称"
                    style="width: 400px;"
                    enter-button
                    @search="getLiveCategory(1,lcState.limit)"
                />
				<a-button type="primary"
                          v-has="{action:'wxapp_live_type_add',plat:isShop}"
                          @click="showEditLiveCategory(0)">
                    <i class="ri-add-line"></i>新增分类
                </a-button>
				<a-button type="danger"
                          v-has="{action:'wxapp_live_type_del',plat:isShop}"
                          @click="deleteLiveCategory(lcState.pks)">
                    <i class="ri-delete-bin-5-line"></i>批量删除
                </a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table row-key="id" :data-source="lcState.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'分类名称',dataIndex:'name'},
				{title:'是否显示',dataIndex:'status',slots:{customRender:'status'}},
				{title:'排序',dataIndex:'rank'},
				{title:'最后更新时间',dataIndex:'update_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" :row-selection="{ selectedRowKeys: lcState.pks, onChange:(e)=>{lcState.pks = e}  }" >
				<template #status="{record}">
					<a-tag :color="record.is_show == 1 ?'#00CC66':'#999'">
                        {{record.is_show == 1 ?'显示':'隐藏'}}
                    </a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button title="编辑"
                                   type="primary"
                                   icon="ri-edit-line"
                                   v-has="{action:'wxapp_live_type_add',is_plat:isShop }"
                                   @click="showEditLiveCategory(record)">
                        </kd-button>
						<kd-button title="删除"
                                   type="danger"
                                   icon="ri-delete-bin-5-line"
                                   v-has="{action:'wxapp_live_type_del',is_plat:isShop }"
                                   @click="deleteLiveCategory(record.id)">
                        </kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
				    show-size-changer
				    :default-current="lcState.page"
				    :total="lcState.count"
				    @showSizeChange="(p,e)=>{getLiveCategory(lcState.page,e)}"
					@change="(e)=>{getLiveCategory(e,lcState.limit)}"
				/>
			</div>
		</div>
		<a-modal title="监控分类新增/编辑"
                 width="500px"
                 v-model:visible="elcState.show"
                 @ok="saveLiveCategory"
                 @cancel="elcState.show = false">
		    <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
		    	<a-form-item label="分类名称">
		    		<a-input v-model:value="elcState.form.name"></a-input>
		    	</a-form-item>
		    	<a-form-item label="分类排序" >
		    		<a-input v-model:value="elcState.form.rank" type="number"></a-input>
		    	</a-form-item>
		    </a-form>
		</a-modal>
	</div>
</template>
<script>
import { useLiveCategory,useEditLiveCategory } from '@/models/live'
export default{
	name:'page-live-type',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
        let { lcState,getLiveCategory,deleteLiveCategory } = useLiveCategory()
        getLiveCategory(1,lcState.limit)

        let elcState = useEditLiveCategory(lcState,getLiveCategory)

		return {
            lcState,getLiveCategory,deleteLiveCategory,
            ...elcState
		}
	}
}
</script>
