import {reactive, toRaw} from "vue";
import liveModel from "@/api/live";
import tool from "@/util/tool";
import common from "@/api/common";


/**
 * 修改国标设备信息（设备名称、密码）
 * @param fn 刷新页面方法
 * @returns {}
 */
export function useEditMonitor(fn){
    let emState = reactive({
        show:false,
        form:null,
        update_type:1,  //1修改名称 2修改密码
    })

    function showUpdateDevice(data,type){
        emState.update_type = type
        if( type == 1 ){
            emState.form= {
                id:data.device.id,
                desc:data.cloud_device.desc,
            }
        }
        if( type == 2 ){
            emState.form= {
                id:data.device.id,
                password:"",
                re_password:"",
            }
        }

        emState.show = true
    }

    function saveDeviceInfo(){
        let data = {
            id:emState.form.id,
            data:{}
        }
        if( emState.update_type == 1 ){
            data.data = {desc:emState.form.desc}
        }

        if( emState.update_type == 2 ){
            if( !tool.returnMessage(emState.form.password,"请输入密码") ) return;
            if( emState.form.password != emState.form.re_password ){
                tool.message("两次密码输入不一致")
                return
            }
            data.data = {password:emState.form.password}
        }

        liveModel.updateGbDeviceInfo(data,()=>{
            tool.message("设备信息修改成功")
            emState.show = false
            if( typeof fn == 'function') fn()
        })
    }

    return { emState,showUpdateDevice,saveDeviceInfo }
}

//监控分类信息列表、删除操作
export function useLiveCategory(){
    let lcState = reactive({
        key:'',
        list:[],
        page:1,
        limit:10,
        count:0,
        ids:[],
    })

    function getLiveCategory(page,limit){
        liveModel.getLiveTypeList(page,limit,{key:lcState.key}).then(res=>{
            lcState.list = res.list
            lcState.page = res.page
            lcState.count = res.count
            lcState.limit = limit
        })
    }
    const deleteLiveCategory = id=>liveModel.deleteLiveType(id).then(()=>{
        getLiveCategory(lcState.page,lcState.limit)
    })

    return { lcState,getLiveCategory ,deleteLiveCategory}
}

//监控分类新增、编辑
export function useEditLiveCategory(state,fn){
    let elcState = reactive({
        show:false,
        form:null
    })

    function showEditLiveCategory(data){
        elcState.form = {
            id:data.id ? data.id :0,
            name:data.name ? data.name :'',
            rank:data.rank ? data.rank :99
        }
        elcState.show = true
    }

    function saveLiveCategory(){
        let param = toRaw(elcState.form)
        if( !param.id ) delete param.id
        liveModel.createOrEditLiveType(param).then(()=>{
            elcState.show = false
            if( typeof fn == 'function') fn(state.page,state.limit)
        })
    }

    return{ elcState ,showEditLiveCategory,saveLiveCategory}
}

//监控列表数据
export function useLive(){
    let liveState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        search:{
            live_uid:"",
            title:"",
            open_status:"",
            category_id:""
        }
    })

    function getLive(page,limit){
        liveModel.getLiveList(page,limit,liveState.search,res=>{
            liveState.list = res.list
            liveState.page = res.page
            liveState.count = res.count
            liveState.limit = limit
        })
    }

    function resetSearch(){
        liveState.search = {
            uid:"",
            title:"",
            open_status:"",
            category_id:""
        }
    }

    const deleteLive = id=>common.deleteDataList(id,11,'确认删除该监控吗?').then(()=>{
        getLive(liveState.page,liveState.limit)
    })

    return{ liveState ,getLive,resetSearch,deleteLive }
}
